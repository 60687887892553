<template>
  <div class="about">
    <div class="aboutTop" v-lazy:background-image="bg">
      <div class="aboutTop-top">
        <img v-lazy="text" alt="" srcset="">
      </div>
      <div class="aboutTop-bottom">
        <div class="aboutTop-bottom-left">
          <!-- 地图 -->
          <el-amap vid="amapContainer" :amap-manager="amapManager" class="amap-demo" :events="events">
            <el-amap-marker />
          </el-amap>
        </div>
        <div class="aboutTop-bottom-right">
          <p>邮箱：{{ detailList.email }}</p>
          <!-- <p>咨询热线：{{ detailList.phone }}</p> -->
          <p>服务时间：{{ detailList.serverTime }}</p>
          <p>地址：{{ detailList.address }}</p>
          <div class="aboutTop-bottom-right-list">
            <p>微信咨询：</p>
            <img :src="detailList.wxUrl" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {contactInfo} from '@/api/index.js'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
const amapManager = new AMapManager()
export default {
  data() {
    return {
      bg:require('../../assets/Web/contact/bg.png'),
      text:require('../../assets/Web/contact/text.png'),
      amapManager,
      events: {
      },
      markerList: [
        { marker: [118.052156, 24.60758], name: '软件园三期C03栋' },
      ],
      detailList:{}
    }
  },
  mounted() {
    lazyAMapApiLoaderInstance.load().then(() => {

      this.map = new window.AMap.Map('amapContainer', {
        center: [118.052156, 24.60758], //地图显示的中心位置
        zoom: 20, //地图缩放比例
      })
      this.map.addControl(new window.AMap.ToolBar({
        // 简易缩放模式，默认为 false
        liteStyle: true
      }));
      //地图标记点方法
      this.createMark()
      this.contactInfo()
    })
  },
  methods: {
    contactInfo(){
      contactInfo().then(res=>{
        this.detailList=res.data
      })
    },
    createMark() {
      // const icon = new window.AMap.Icon({
      //   size: new window.AMap.Size(24, 24),
      //   image: this.iconPoint,
      //   imageSize: new window.AMap.Size(24, 24)
      // })
      // var labelOffset = new window.AMap.Pixel(0, -5);
      this.markerList.forEach((item) => {
        // const labelContent = "<div class='labelContent'>" + item.name + "</div>"
        const marker = new window.AMap.Marker({
          position: item.marker,
          // icon: icon,
          anchor: 'center', //设置锚点
          offset: new window.AMap.Pixel(0, 0), //设置偏移量
          // label: {
          //   direction: 'top',
          //   content: labelContent,
          //   offset: labelOffset,

          // }
        })
        // this.map.add(marker)
        marker.setMap(this.map)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .amap-zoomcontrol{
  display: none;
}
/deep/ .amap-logo,/deep/ .amap-copyright{
  display: none !important;

}
// ::v-deep .amap-labels {
//       width: 100%;
//       height: 100vh;
//       background-color: #F0F3FA !important;
//     }
.about {
  .aboutTop {
    // background: url("../../assets/Web/contact/bg.png");
    height: 681.8PX;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    .aboutTop-top {
      margin-top: 98PX;
      img {
        width: 467.7PX;
        height: 82.4PX;
      }
    }
    .aboutTop-bottom {
      width: 841.8PX;
      height: 323.2PX;
      margin-top: 82PX;
      display: flex;
      .aboutTop-bottom-left {
        height: 100%;
       flex: 1;
      }
      .aboutTop-bottom-right {
        flex: 1;
        height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 52PX 0PX 52PX 30PX;
        box-sizing: border-box;
        p {
          margin-left: 30PX;
          font-size: 17PX;
          color: #333333;
          margin-bottom: 30PX;
        }
        .aboutTop-bottom-right-list{
          display: flex;
          align-items: center;
          p{
          margin-bottom: 0PX;

          }
          img{
            width: 100PX;
            height: 100PX;
            border-radius: 6PX;
            // background-color: #EEEEEE;
          }
        }
      }
    }
  }
}
</style>
